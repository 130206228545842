var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c(
            "div",
            {
              staticClass:
                "inner-container only-heading-part header-with-banner",
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "v-main-content only-simple-content",
                  attrs: { fluid: "" },
                },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-4 rounded-lg extra-padding",
                        attrs: { elevation: "3" },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { id: "app" } },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "px-0 pt-0 pb-0" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "sub-heading-with-btn d-flex flex-wrap",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "black-text" },
                                      [
                                        _vm._v(" Super Admin Panel "),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "max-width": "300px",
                                              color: "primarydark",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "greyxdark--text ml-1",
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "mdi-information-outline"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Super Admin Panel"),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-tabs",
                              {
                                model: {
                                  value: _vm.tab,
                                  callback: function ($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab",
                                },
                              },
                              _vm._l(_vm.items, function (item) {
                                return _c("v-tab", { key: item }, [
                                  _vm._v(" " + _vm._s(item) + " "),
                                ])
                              }),
                              1
                            ),
                            _c(
                              "v-tabs-items",
                              {
                                model: {
                                  value: _vm.tab,
                                  callback: function ($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab",
                                },
                              },
                              [
                                _c(
                                  "v-tab-item",
                                  [
                                    _c(
                                      "v-card",
                                      { attrs: { color: "basil", flat: "" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex justify-end" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary ml-3",
                                                  depressed: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openRetentionPeriodDialog()
                                                  },
                                                },
                                              },
                                              [_vm._v(" Edit Log Retention ")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-data-table", {
                                          staticClass:
                                            "elevation-0 mt-4 table-style-3",
                                          attrs: {
                                            "item-key": "logId",
                                            "items-per-page": 50,
                                            headers: _vm.activityLogHeaders,
                                            items: _vm.filteredActivityLogs,
                                            "no-data-text": "No logs available",
                                            search: _vm.search,
                                            "show-expand": "",
                                            "single-expand": true,
                                            "footer-props": {
                                              itemsPerPageOptions: [
                                                50,
                                                100,
                                                200,
                                              ],
                                            },
                                            loading: _vm.loading,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "body.prepend",
                                                fn: function () {
                                                  return [
                                                    _c("tr", [
                                                      _c("td", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pt-0 d-flex px-0 pb-3 date-picker w-100 z-999",
                                                          },
                                                          [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                ref: "menu",
                                                                staticClass:
                                                                  " ",
                                                                attrs: {
                                                                  "close-on-content-click": false,
                                                                  "return-value":
                                                                    _vm.rangePicker,
                                                                  transition:
                                                                    "scale-transition",
                                                                  "offset-y":
                                                                    "",
                                                                  left: "",
                                                                },
                                                                on: {
                                                                  "update:returnValue": function (
                                                                    $event
                                                                  ) {
                                                                    _vm.rangePicker = $event
                                                                  },
                                                                  "update:return-value": function (
                                                                    $event
                                                                  ) {
                                                                    _vm.rangePicker = $event
                                                                  },
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-text-field",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "v-date-picker-cs w-sm-100 z-999",
                                                                                  staticStyle: {
                                                                                    "font-size":
                                                                                      "revert",
                                                                                  },
                                                                                  attrs: {
                                                                                    "prepend-icon":
                                                                                      "mdi-calendar",
                                                                                    readonly:
                                                                                      "",
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm.filterDateRangeText,
                                                                                    callback: function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.filterDateRangeText = $$v
                                                                                    },
                                                                                    expression:
                                                                                      "filterDateRangeText",
                                                                                  },
                                                                                },
                                                                                "v-text-field",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            )
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]
                                                                ),
                                                                model: {
                                                                  value:
                                                                    _vm.rangePicker,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.rangePicker = $$v
                                                                  },
                                                                  expression:
                                                                    "rangePicker",
                                                                },
                                                              },
                                                              [
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle: {
                                                                        display:
                                                                          "flex",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle: {
                                                                            flex:
                                                                              "auto",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              attrs: {
                                                                                max:
                                                                                  _vm.maxDate,
                                                                                min:
                                                                                  _vm
                                                                                    .retentionStartDate[0],
                                                                                "no-title":
                                                                                  "",
                                                                                scrollable:
                                                                                  "",
                                                                                range:
                                                                                  "",
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.filterDateRangeModel,
                                                                                callback: function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.filterDateRangeModel = $$v
                                                                                },
                                                                                expression:
                                                                                  "filterDateRangeModel",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    text:
                                                                                      "",
                                                                                    color:
                                                                                      "grey",
                                                                                  },
                                                                                  on: {
                                                                                    click: function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.rangePicker = false
                                                                                    },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Cancel"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    disabled:
                                                                                      _vm.filterDateRangeModel &&
                                                                                      _vm
                                                                                        .filterDateRangeModel
                                                                                        .length !==
                                                                                        2,
                                                                                    text:
                                                                                      "",
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                                  on: {
                                                                                    click: function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.applyDateRange()
                                                                                    },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "OK"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Account Name",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.companyName,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.companyName = $$v
                                                              },
                                                              expression:
                                                                "companyName",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c("v-select", {
                                                            staticClass: "mt-3",
                                                            attrs: {
                                                              items:
                                                                _vm.uniqueLogTypes,
                                                              label: "Log Type",
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              attach: "",
                                                              multiple: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedLogType,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.selectedLogType = $$v
                                                              },
                                                              expression:
                                                                "selectedLogType",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c("v-select", {
                                                            staticClass: "mt-3",
                                                            attrs: {
                                                              items:
                                                                _vm.uniqueFeatureNames,
                                                              label:
                                                                "Feature Name",
                                                              required: "",
                                                              outlined: "",
                                                              dense: "",
                                                              attach: "",
                                                              multiple: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedFeature,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.selectedFeature = $$v
                                                              },
                                                              expression:
                                                                "selectedFeature",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label: "Message",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.message,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.message = $$v
                                                              },
                                                              expression:
                                                                "message",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c("td"),
                                                      _c("td"),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "item.message",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {},
                                                                        "span",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.message
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  item.featurePath
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "feature-path",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.featurePath
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.message)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.createdOn",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.unixToLocal(
                                                                  item.createdOn
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-para-heading ml-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "(" +
                                                                    _vm.calcTimeAgo(
                                                                      item.createdOn
                                                                    ) +
                                                                    ")"
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.options",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  !item.watched
                                                                    ? _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1 font-22 icon-hover",
                                                                              attrs: {
                                                                                color:
                                                                                  "primary",
                                                                                dark:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.changeWatched(
                                                                                    item.logId,
                                                                                    true
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-eye-outline "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1 font-22 icon-hover",
                                                                              attrs: {
                                                                                color:
                                                                                  "primary",
                                                                                dark:
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.changeWatched(
                                                                                    item.logId,
                                                                                    false
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-eye-check "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        !item.watched
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Set Watch to true?"
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                "Set Watch to false?"
                                                              ),
                                                            ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mr-1 font-22 icon-hover",
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            dark:
                                                                              "",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.copyJSON(
                                                                                item
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-content-copy "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Copy Log"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mr-1 icon-hover",
                                                                          attrs: {
                                                                            run:
                                                                              "",
                                                                            color:
                                                                              "primary",
                                                                            dark:
                                                                              "",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.openDeleteDialog(
                                                                                item.logId
                                                                              )
                                                                            },
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-delete-outline "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Delete"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "expanded-item",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass: "px-0",
                                                        attrs: { colspan: "7" },
                                                      },
                                                      [
                                                        _c("json-viewer", {
                                                          attrs: {
                                                            "expand-depth": 20,
                                                            value: item,
                                                            copyable: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  [
                                    _c(
                                      "v-card",
                                      { attrs: { color: "basil", flat: "" } },
                                      [
                                        _c("v-data-table", {
                                          staticClass:
                                            "elevation-0 mt-4 table-style-3",
                                          attrs: {
                                            headers: _vm.mockDataHeader,
                                            items: _vm.mockDataItems,
                                            "items-per-page": 5,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.name",
                                              fn: function (ref) {
                                                return [
                                                  _vm._v(" Mock Data Group "),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.org",
                                              fn: function (ref) {
                                                return [
                                                  _vm._v(" Mock Data Group "),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.status",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  item.progress == 100
                                                    ? [_vm._v(" Done ")]
                                                    : [
                                                        item.deleting
                                                          ? [
                                                              _vm._v(
                                                                " Deleting "
                                                              ),
                                                            ]
                                                          : [
                                                              _vm._v(
                                                                " Generating "
                                                              ),
                                                            ],
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              item.progress
                                                            ) +
                                                            "%) "
                                                        ),
                                                      ],
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.delete",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "mx-1 icon-hover",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                      attrs: {
                                                        color: "primary",
                                                        disabled:
                                                          item.progress != 100,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openDeleteIntegrationDialog(
                                                            item.TeamID
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-delete-outline"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                        _c(
                                          "v-row",
                                          { staticClass: "py-6" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  lg: "4",
                                                  md: "6",
                                                  sm: "12",
                                                },
                                              },
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    label: "Team Name",
                                                    items: _vm.memberDetails,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    "item-value": "TeamID",
                                                    "item-text": "Email",
                                                    attach: "",
                                                  },
                                                  model: {
                                                    value: _vm.selectedTeamID,
                                                    callback: function ($$v) {
                                                      _vm.selectedTeamID = $$v
                                                    },
                                                    expression:
                                                      "selectedTeamID",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  lg: "2",
                                                  md: "6",
                                                  sm: "12",
                                                },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "App Count",
                                                    outlined: "",
                                                    dense: "",
                                                    rules: _vm.numberRules,
                                                  },
                                                  model: {
                                                    value: _vm.volume.appCount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.volume,
                                                        "appCount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "volume.appCount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  lg: "2",
                                                  md: "6",
                                                  sm: "12",
                                                },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Campaign Count",
                                                    outlined: "",
                                                    dense: "",
                                                    rules: _vm.numberRules,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.volume.campaignCount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.volume,
                                                        "campaignCount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "volume.campaignCount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  lg: "2",
                                                  md: "6",
                                                  sm: "12",
                                                },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label:
                                                      "Adgroups Per Campaign",
                                                    outlined: "",
                                                    dense: "",
                                                    rules: _vm.numberRules,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.volume.adgroupCount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.volume,
                                                        "adgroupCount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "volume.adgroupCount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  lg: "2",
                                                  md: "6",
                                                  sm: "12",
                                                },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label:
                                                      "Keywords Per Adgroup",
                                                    outlined: "",
                                                    dense: "",
                                                    rules: _vm.numberRules,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.volume.keywordCount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.volume,
                                                        "keywordCount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "volume.keywordCount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "d-flex justify-end" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary ml-3",
                                                  depressed: "",
                                                  disabled: !_vm.countMockdata,
                                                },
                                                on: { click: _vm.addMockData },
                                              },
                                              [_vm._v(" Add Mock Data ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  [
                                    _c(
                                      "v-card",
                                      { attrs: { color: "basil", flat: "" } },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                [
                                                  _c("v-treeview", {
                                                    attrs: {
                                                      items: _vm.sarFileTree,
                                                      transition: "",
                                                      "item-key": "id",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "prepend",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          var open = ref.open
                                                          return [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "offset-x": true,
                                                                  bottom: "",
                                                                  right: "",
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "v-icon",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-dots-vertical"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list",
                                                                  [
                                                                    !item.file
                                                                      ? [
                                                                          _c(
                                                                            "v-list-item",
                                                                            {
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.startFunctionCreation(
                                                                                    item
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    " New Function "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-list-item",
                                                                            {
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openCreateFolder(
                                                                                    item
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    " New Folder "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          item.id !=
                                                                          1
                                                                            ? [
                                                                                _c(
                                                                                  "v-list-item",
                                                                                  {
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openDeleteFolder(
                                                                                          item
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-title",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Delete "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-list-item",
                                                                                  {
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openRenameDialog(
                                                                                          item
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-title",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Rename "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            : _vm._e(),
                                                                        ]
                                                                      : [
                                                                          _vm.functionDetails &&
                                                                          _vm
                                                                            .functionDetails
                                                                            .isFromDB &&
                                                                          item.id ===
                                                                            _vm
                                                                              .functionDetails
                                                                              .id
                                                                            ? [
                                                                                _c(
                                                                                  "v-list-item",
                                                                                  {
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openMoveDialog(
                                                                                          item,
                                                                                          true
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-title",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Copy To"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-list-item",
                                                                                  {
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.publicPrivateFn(
                                                                                          item.id
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-title",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .functionDetails
                                                                                              .isPublic
                                                                                              ? "Make Private"
                                                                                              : "Make Public"
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-list-item",
                                                                                  {
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.lockUnlockFn(
                                                                                          item.id
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-title",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .functionDetails
                                                                                              .isLocked
                                                                                              ? "Unlock"
                                                                                              : "Lock"
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "v-list-item",
                                                                            {
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.openDeleteFunction(
                                                                                    item
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Delete"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                    item.id != 1
                                                                      ? _c(
                                                                          "v-list-item",
                                                                          {
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.openMoveDialog(
                                                                                  item
                                                                                )
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-list-item-title",
                                                                              [
                                                                                _vm._v(
                                                                                  "Move To"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            !item.file
                                                              ? _c("v-icon", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        open
                                                                          ? "mdi-folder-open"
                                                                          : "mdi-folder"
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ])
                                                              : _c("v-icon", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .files[
                                                                          item
                                                                            .file
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "label",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          return [
                                                            item.file
                                                              ? [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        cursor:
                                                                          "pointer",
                                                                      },
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectFunction(
                                                                            item
                                                                          )
                                                                        },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.name +
                                                                              "." +
                                                                              item.file
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              : [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.name
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ],
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                    model: {
                                                      value: _vm.tree,
                                                      callback: function ($$v) {
                                                        _vm.tree = $$v
                                                      },
                                                      expression: "tree",
                                                    },
                                                  }),
                                                ],
                                              ],
                                              2
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "9" } },
                                              [
                                                _vm.currentFunction &&
                                                _vm.currentFunction.id
                                                  ? [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      "",
                                                                    label:
                                                                      "Function Name",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentFunction
                                                                        .name,
                                                                    callback: function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.currentFunction,
                                                                        "name",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "currentFunction.name",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Function Description",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .functionDetails
                                                                        .description,
                                                                    callback: function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.functionDetails,
                                                                        "description",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "functionDetails.description",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("FunctionEditor", {
                                                        attrs: {
                                                          validate:
                                                            _vm.validate,
                                                          template:
                                                            _vm.currentTemplate,
                                                          scriptedArFunctions:
                                                            _vm.sarFunctions,
                                                        },
                                                        on: {
                                                          valid: function (
                                                            $event
                                                          ) {
                                                            return _vm.functionValues(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            depressed: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.validateFunction()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Validate")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            depressed: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.validateAndSaveFn()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Save")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.validateAndTestFn()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Test Run")]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-tab-item", [_c("BillingQuery")], 1),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                ],
                2
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", width: "500" },
                  model: {
                    value: _vm.moveItemDialog,
                    callback: function ($$v) {
                      _vm.moveItemDialog = $$v
                    },
                    expression: "moveItemDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "d-flex align-center justify-center flex-column",
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                        },
                        [
                          _c("h5", [
                            _vm._v(
                              _vm._s(_vm.copying ? "Copy" : "Move") + " To"
                            ),
                          ]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "white--text",
                              on: {
                                click: function ($event) {
                                  return _vm.closeMoveDialog()
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          [
                            _c(
                              "v-container",
                              { staticClass: "pa-0", attrs: { height: "150" } },
                              [
                                _c("v-treeview", {
                                  attrs: {
                                    items: _vm.menuTree,
                                    activatable: "",
                                    transition: "",
                                    active: _vm.activeTreeItems,
                                    "item-key": "id",
                                  },
                                  on: {
                                    "update:active": function ($event) {
                                      _vm.activeTreeItems = $event
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "prepend",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var open = ref.open
                                        return [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  open
                                                    ? "mdi-folder-open"
                                                    : "mdi-folder"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "label",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                      _c(
                        "v-card-actions",
                        {
                          staticClass:
                            "d-flex justify-end align-center w-100 pb-6 pt-0 px-6",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { width: "100px", color: " " },
                              on: {
                                click: function ($event) {
                                  return _vm.closeMoveDialog()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("deleteCampaign.cancel")) +
                                  " "
                              ),
                            ]
                          ),
                          _vm.copying
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: !_vm.activeTreeItems.length,
                                    width: "60px",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyTreeItem()
                                    },
                                  },
                                },
                                [_vm._v(" Copy ")]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    width: "60px",
                                    disabled: !_vm.activeTreeItems.length,
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveTreeItem()
                                    },
                                  },
                                },
                                [_vm._v(" Move ")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", width: "500" },
                  model: {
                    value: _vm.treeItemDialog,
                    callback: function ($$v) {
                      _vm.treeItemDialog = $$v
                    },
                    expression: "treeItemDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "d-flex align-center justify-center flex-column",
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                        },
                        [
                          _c("h5", [_vm._v("Create Folder")]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "white--text",
                              on: {
                                click: function ($event) {
                                  return _vm.closeFolderDialog()
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          [
                            _c(
                              "v-container",
                              { staticClass: "pa-0", attrs: { height: "150" } },
                              [
                                _c("h5", [_vm._v("Folder Name")]),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("v-text-field", {
                                      model: {
                                        value: _vm.folderNameModel,
                                        callback: function ($$v) {
                                          _vm.folderNameModel = $$v
                                        },
                                        expression: "folderNameModel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                        ],
                        2
                      ),
                      _c(
                        "v-card-actions",
                        {
                          staticClass:
                            "d-flex justify-end align-center w-100 pb-6 pt-0 px-6",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { width: "100px", color: " " },
                              on: {
                                click: function ($event) {
                                  return _vm.closeFolderDialog()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("deleteCampaign.cancel")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { width: "60px", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.createFolder()
                                },
                              },
                            },
                            [_vm._v(" Create ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", width: "500" },
                  model: {
                    value: _vm.renameFolderDialog,
                    callback: function ($$v) {
                      _vm.renameFolderDialog = $$v
                    },
                    expression: "renameFolderDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "d-flex align-center justify-center flex-column",
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                        },
                        [
                          _c("h5", [_vm._v("Rename Folder")]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "white--text",
                              on: {
                                click: function ($event) {
                                  return _vm.closeRenameDialog()
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { staticClass: "pa-0", attrs: { height: "150" } },
                            [
                              _c("h5", [_vm._v("New Folder Name")]),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-text-field", {
                                    model: {
                                      value: _vm.newFolderName,
                                      callback: function ($$v) {
                                        _vm.newFolderName = $$v
                                      },
                                      expression: "newFolderName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        {
                          staticClass:
                            "d-flex justify-end align-center w-100 pb-6 pt-0 px-6",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { width: "100px", color: " " },
                              on: {
                                click: function ($event) {
                                  return _vm.closeRenameDialog()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { width: "60px", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.renameFolder()
                                },
                              },
                            },
                            [_vm._v("Rename")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "600px", persistent: "" },
                  model: {
                    value: _vm.retentionPeriodDialog,
                    callback: function ($$v) {
                      _vm.retentionPeriodDialog = $$v
                    },
                    expression: "retentionPeriodDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "v-modal-fixed style-2" },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "headline d-flex justify-space-between px-5 py-3 v-modal-header",
                        },
                        [
                          _c("h6", [_vm._v("Retention Period")]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "black--text",
                              attrs: { disabled: _vm.saving },
                              on: {
                                click: function ($event) {
                                  _vm.retentionPeriodDialog = false
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          [
                            _c(
                              "v-container",
                              { staticClass: "pa-0", attrs: { height: "150" } },
                              [
                                _c("h5", [_vm._v("Change Retention Period")]),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("v-text-field", {
                                      model: {
                                        value: _vm.retentionPeriod,
                                        callback: function ($$v) {
                                          _vm.retentionPeriod = $$v
                                        },
                                        expression: "retentionPeriod",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                        ],
                        2
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "justify-end mt-0 pa-5" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                width: "127",
                                color: "primary",
                                depressed: "",
                                loading: _vm.saving,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeRetentionPeriod()
                                },
                              },
                            },
                            [_vm._v("Apply")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "600px" },
                  model: {
                    value: _vm.testRunDialog,
                    callback: function ($$v) {
                      _vm.testRunDialog = $$v
                    },
                    expression: "testRunDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(" Test Run Function "),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.closeTestRunDialog()
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Input Parameters (JSON format)",
                              hint: "Enter parameters in JSON format",
                              spellcheck: "false",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.testRunParams,
                              callback: function ($$v) {
                                _vm.testRunParams = $$v
                              },
                              expression: "testRunParams",
                            },
                          }),
                          _c("v-textarea", {
                            attrs: {
                              label: "Console Logs",
                              readonly: true,
                              hint: "Output will appear here",
                              "persistent-hint": "",
                              "auto-grow": "",
                            },
                            model: {
                              value: _vm.testRunLogs,
                              callback: function ($$v) {
                                _vm.testRunLogs = $$v
                              },
                              expression: "testRunLogs",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.executeTestRun()
                                },
                              },
                            },
                            [_vm._v("Run")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("DeleteIntegration", {
            attrs: { integrationDetailsForDeletion: _vm.integrationData },
          }),
          _c("DecisionAlert", {
            attrs: {
              DecisionAlert: _vm.decisionAlertFlag,
              DecisionAlertTitle: _vm.decisionAlertTitle,
              DecisionAlertIcon: _vm.decisionAlertIcon,
              DecisionAlertMessage: _vm.decisionAlertMessage,
              DecisionAlertData: _vm.decisionAlertData,
            },
            on: {
              emitDecisionAlertCancelClicked: function ($event) {
                _vm.decisionAlertFlag = false
              },
              emitDecisionAlertOkClicked: function ($event) {
                return _vm.decisionOkAction($event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }