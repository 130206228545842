import moment from 'moment';

interface IFormData {
  customer: string;
  minAmount: number;
  asaSpend: number;
  startDate: string; // e.g "2024-05-24"
  endDate: string;
}

class BillingFormService {
  formData: IFormData = {
    customer: null,
    minAmount: null,
    asaSpend: null,
    startDate: null /* e.g "2024-05-24" */,
    endDate: null,
  };

  constructor() {
    this.initialiseForm();
  }

  get isCompleteFormFilled() {
    return this.formData.customer && this.formData.minAmount && this.formData.asaSpend && this.formData.startDate && this.formData.endDate;
  }

  get isFormInvalid(){
    return this.isDateSelectInvalid || this.isEnteredAmountNegative;
  }

  /* @desc - startDate should be less than endDate */
  private get isDateSelectInvalid() {
    return new Date(this.formData.startDate) > new Date(this.formData.endDate);
  }

  private get isEnteredAmountNegative() {
    return this.formData.minAmount < 0;
  }

  updateStartDate(e) {
    this.formData.startDate = e;
  }

  updateEndDate(e) {
    this.formData.endDate = e;
  }

  private initialiseForm() {
    this.formData.minAmount = 500;
    this.formData.asaSpend = 2;
    const { lastMonthStartDate, lastMonthEndDate } = this.getLastMonthDates();
    this.formData.startDate = lastMonthStartDate;
    this.formData.endDate = lastMonthEndDate;
  }

  /* @desc - this gives the last month's startDate and endDate */
  private getLastMonthDates() {
    const lastMonthStartDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    const lastMonthEndDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    return { lastMonthStartDate, lastMonthEndDate };
  }
}

export default BillingFormService;
