var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { attrs: { color: "basil", flat: "" } }, [
    _c("section", { staticClass: "billing-form" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "billing-customer" } },
            [_vm._v("Customer")]
          ),
          _c("v-autocomplete", {
            staticClass: "form-field",
            attrs: {
              id: "billing-customer",
              items: _vm.customerList,
              label: "Customers",
              "item-text": "CompanyName",
              "item-value": "TeamID",
              dense: "",
              outlined: "",
            },
            model: {
              value: _vm.billingFormService.formData.customer,
              callback: function ($$v) {
                _vm.$set(_vm.billingFormService.formData, "customer", $$v)
              },
              expression: "billingFormService.formData.customer",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "billing-min-amount" } },
            [_vm._v("Min Amount "), _c("br"), _vm._v("(In USD)")]
          ),
          _c("v-text-field", {
            staticClass: "form-field",
            attrs: {
              id: "billing-min-amount",
              label: "Enter Min Amount",
              outlined: "",
              type: "number",
              dense: "",
            },
            model: {
              value: _vm.billingFormService.formData.minAmount,
              callback: function ($$v) {
                _vm.$set(_vm.billingFormService.formData, "minAmount", $$v)
              },
              expression: "billingFormService.formData.minAmount",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "billing-asa-spend" } },
            [_vm._v("ASA Spend"), _c("br"), _vm._v("(%)")]
          ),
          _c("v-text-field", {
            staticClass: "form-field",
            attrs: {
              id: "billing-asa-spend",
              label: "Enter Asa Spend %",
              outlined: "",
              type: "number",
              dense: "",
            },
            model: {
              value: _vm.billingFormService.formData.asaSpend,
              callback: function ($$v) {
                _vm.$set(_vm.billingFormService.formData, "asaSpend", $$v)
              },
              expression: "billingFormService.formData.asaSpend",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "billing-startdate" } },
            [_vm._v("Start Date")]
          ),
          _c("DatePicker", {
            attrs: {
              id: "billing-startdate",
              initialDate: _vm.billingFormService.formData.startDate,
              label: "Enter Start Date",
            },
            on: {
              dateChange: function ($event) {
                return _vm.billingFormService.updateStartDate($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "billing-enddate" } },
            [_vm._v("End Date")]
          ),
          _c("DatePicker", {
            attrs: {
              id: "billing-enddate",
              initialDate: _vm.billingFormService.formData.endDate,
              label: "Enter End Date",
            },
            on: {
              dateChange: function ($event) {
                return _vm.billingFormService.updateEndDate($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "footer-section d-flex justify-space-between align-center h-65",
        },
        [
          _c(
            "div",
            [
              _c("BillingOutput", {
                attrs: { billingResponse: _vm.billingResponse },
              }),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                depressed: "",
                color: "primary",
                disabled: _vm.isDisabled,
              },
              on: {
                click: function ($event) {
                  return _vm.calculateBill()
                },
              },
            },
            [_vm._v(" Calculate Bill ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }