import gql from 'graphql-tag';

export const TOTAL_SPEND = gql`
  query getCBSpend(
    $teamId: String!
    $from: String!
    $to: String!
    $monthlyMinAmount: String!
    $asaSpendPercent: String!
  ) {
    getCBSpend: getCBSpend(
      inputs: {
        teamId: $teamId
        from: $from
        to: $to
        monthlyMin: $monthlyMinAmount
        asaSpendPercent: $asaSpendPercent
      }
    ) {
      spend
      currency
      exchangeRate
      planActiveDays
      totalASASpend
      asaSpendPercentage
      monthMinBillPercentage
    }
  }
`;

export interface I_TOTAL_SPEND {
  getCBSpend: {
    spend: number;
    currency: string;
    exchangeRate: number;
    planActiveDays: number;
    totalASASpend: number;
    asaSpendPercentage: number;
    monthMinBillPercentage: number;
  };
}
