var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.billingResponse
    ? _c("div", { staticClass: "output-section d-flex flex-column" }, [
        _c("output", { staticClass: "d-flex justify-space-between" }, [
          _vm._v(" Calculated Bill : "),
          _c("span", [_vm._v(_vm._s(_vm.getOutputText("calculatedBill")))]),
        ]),
        _c("output", { staticClass: "d-flex justify-space-between" }, [
          _vm._v(" Plan Active Days : "),
          _c("span", [_vm._v(_vm._s(_vm.getOutputText("planActiveInDays")))]),
        ]),
        _c("output", { staticClass: "d-flex justify-space-between" }, [
          _vm._v(" Total ASA Spend : "),
          _c("span", [_vm._v(_vm._s(_vm.getOutputText("totalAsaSpend")))]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }