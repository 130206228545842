

















// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
  name: 'BillingOutput',
  data() {
    return {};
  },
  props: {
    billingResponse: {
      type: Object,
      required: false,
    },
  },
  methods: {
    getOutputText(type) {
      if (JSON.stringify(this.billingResponse) === '{}') {
        return 'NA';
      }
      if (type === 'calculatedBill') {
        return this.billingResponse.spend + this.billingResponse.currency;
      }
      if (type === 'planActiveInDays') {
        return this.billingResponse.planActiveDays;
      }
      if (type === 'totalAsaSpend') {
        return this.billingResponse.totalASASpend;
      }
    },
  },
});
