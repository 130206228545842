




















// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
  name: 'DatePicker',
  components: {},
  data() {
    return {
      dateMenu: false,
      selectedDate: ""
    };
  },
  props: {
    initialDate: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    }
  },
  methods: {
    changeDate() {
      this.$refs.dateMenu.save(this.selectedDate);
      this.$emit('dateChange', this.selectedDate);
    },
  },
  computed: {
    myComputed() {
      // logic
    },
  },
  mounted() {
    this.selectedDate = this.initialDate
  },
});
