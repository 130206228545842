











import * as _ from 'lodash';
import Vue from 'vue';
import EslintBuild from 'eslint4b-prebuilt';

import { EditorState } from "@codemirror/state";
import { EditorView, keymap, ViewUpdate } from "@codemirror/view";
import { defaultKeymap } from "@codemirror/commands";
import { javascript, javascriptLanguage } from '@codemirror/lang-javascript';

import { basicSetup } from "codemirror";
import { autocompletion, CompletionContext } from '@codemirror/autocomplete';
import { HighlightStyle, syntaxHighlighting, syntaxTree } from '@codemirror/language';
import { SyntaxNodeRef } from '@lezer/common';
import { Diagnostic, lintGutter, setDiagnostics } from '@codemirror/lint';
import { tags as t } from '@lezer/highlight';


export default Vue.extend({
  name: 'FunctionEditor',
  props: {
    validate: {
      type: Object,
      default: null
    },
    template: {
      type: String,
      default: null
    },
    scriptedArFunctions: {
      type: Array,
      default: () => []
    },
    type: String
  },
  data() {
    return {
      cleanLingeringErrors: false,
      areThereErrors: false,
      startState: {} as EditorState,
      view: {} as EditorView
    }
  },

  watch: {
    validate: function(value) {
        if(value) {
          this.cleanLingeringErrors = true;
          this.lintAndEmit();
        }
    },
    template: function (value) {
      if (value) {
        this.view.dispatch({
          changes: { from: 0, to: this.view.state.doc.length, insert: value }
        })
      } else {
        this.view.dispatch({
          changes: { from: 0, to: this.view.state.doc.length, insert: '' }
        })
      }
    }
  },

  methods: {
    lintAndEmit() {
      const nodes = [];
      syntaxTree(this.view.state).cursor().iterate((item: SyntaxNodeRef) => {
        const node = _.cloneDeep(item);
        nodes.push(node);
      });
      
      const jsLintdiagnostics = this.lintJs(nodes);
      const esLintdiagnostics = this.lintEs();
      const diagnostics = [...jsLintdiagnostics, ...esLintdiagnostics];
      const jsLintSpec = setDiagnostics(this.view.state, diagnostics);
      
      this.view.dispatch(jsLintSpec);
      
      let functionFind;
      
      nodes.every(n => {
        if(n.name === 'FunctionDeclaration') functionFind = true;
        
        if(functionFind && n.name === 'VariableDefinition') {
          functionFind = this.view.state.sliceDoc(n.from, n.to);
          return false;
        }
        
        return true;
      })
      
      const valid = !diagnostics.length;
      
      this.$emit('valid', { isValid: valid, diagnostics, code: this.view.state.sliceDoc(), name: functionFind });
    },
    lintJs(nodes: SyntaxNodeRef[]) {
      const diagnostics: Diagnostic[] = []; 
      let firstNode, functionNode, lastNode, fnCounter = 0;
      for(let node of nodes) {
        
        lastNode = node;
        if(!firstNode) firstNode = node;
        
        if(node.name === 'FunctionDeclaration') {
          fnCounter++;
          if(!functionNode) functionNode = node;
        }
        
        if (!['Script', 'IfStatement',
          'if',
          'ParenthesizedExpression',
          'else',
          'Block',
          'BinaryExpression',
          'MemberExpression',

          'ArrowFunction',
          'FunctionExpression',
          'ParamList',
          'Arrow',
          'function',
          'ReturnStatement',
          'return',
          'static',
          'extern',
         

          'VariableName',
          'VariableDeclaration',
          'VariableDefinition',
          'var',
          'const',
          'let',
          'Equals',
          'NewExpression',
          'new',
          ',',
          'PropertyName',
          'BooleanLiteral',
          'CompareOp',
          'ArithOp',
          'UpdateOp',
          'LogicOp',
          'Number',
          'String',
          '(',
          ')',
          '{',
          '}',
          ';',
          '[',
          ']',
          'ArrayExpression',
          'ForStatement',
          'for',
          'ForSpec',
          'PostfixExpression',
          'ArrowFunction',
          'FunctionExpression',
          'ParamList',
          'Arrow',
          'function',
          'ReturnStatement',
          'return',
          'static',
          'extern',
          'ExpressionStatement',
          'CallExpression',
          'FunctionDeclaration',
          'VariableName',
          'AssignmentExpression',
          'ObjectExpression',
          'Property',
          'PropertyDefinition',
          ':',
          'ArgList', '⚠', '.', 'LineComment'].includes(node.name)) {

          const found = diagnostics.find(diagnostic => diagnostic.from <= node.from && diagnostic.to >= node.to);

          if (!found) {
            diagnostics.push({
              from: node.from,
              to: node.to,
              source: 'scripted-ar-linter',
              severity: "error",
              message: node.name + " is prohibited."
            })
          }

        } else if (['⚠'].includes(node.name)) {
          diagnostics.push({
            from: node.from,
            to: node.to,
            source: 'scripted-ar-linter',
            severity: "error",
            message: "Expression expected"
          })
        }
      }
      
      if(fnCounter !== 1) {
          diagnostics.push({
            from: 0,
            to: lastNode.to,
            source: 'scripted-ar-linter',
            severity: "error",
            message: "Only one function declaration is allowed"
          })
      }
      
      for(const node of nodes) {
        if(node.name !== 'FunctionDeclaration') {
          if(node.from < functionNode?.from || node.to > functionNode?.to) {
              diagnostics.push({
              from: 0,
              to: lastNode.to,
              source: 'scripted-ar-linter',
              severity: "error",
              message: "Only function declaration is allowed"
            })
            break;
          }
        }
      }
        
      return diagnostics;
    },
    lintEs() {
      let globals = {};
      
      const esLintConst = new EslintBuild();

      const rules = { 'no-unused-vars': 0 };
      
      esLintConst.getRules().forEach((desc, name) => {
        if (desc.meta.docs.recommended && rules[name] === undefined)
          rules[name] = 2;
      });
      
      Object.assign(globals, this.scriptedArFunctions.map(fn => fn.split('(')[0]).reduce((a, v) => ({ ...a, [v]: 'readonly' }), {}));
      const config = {
        parserOptions: { ecmaVersion: 2019, sourceType: "module" },
        env: {},
        rules,
        globals
      };
      let { state } = this.view, found = [];
      for (let { from, to } of javascriptLanguage.findRegions(state)) {
        let fromLine = state.doc.lineAt(from), offset = { line: fromLine.number - 1, col: from - fromLine.from, pos: from };

        for (let d of esLintConst.verify(state.sliceDoc(from, to), config))
          found.push(this.translateDiagnostic(d, state.doc, offset));
      }

      return found as Diagnostic[];
    },
    toSet(chars) {
      let flat = Object.keys(chars).join("");
      let words = /\w/.test(flat);
      if (words)
        flat = flat.replace(/\w/g, "");
      return `[${words ? "\\w" : ""}${flat.replace(/[^\w\s]/g, "\\$&")}]`;
    },

    prefixMatch(options) {
      let first = Object.create(null), rest = Object.create(null);
      for (let { label } of options) {
        first[label[0]] = true;
        for (let i = 1; i < label.length; i++)
          rest[label[i]] = true;
      }
      let source = this.toSet(first) + this.toSet(rest) + "*$";
      return [new RegExp("^" + source), new RegExp(source)];
    },

    completionFromList(context, list: string[], type: string) {
      const options = list.map(p => Object.assign({ label: p, type }));
      const [validFor, match] = options.every(o => /^\w+$/.test(o.label)) ? [/\w*$/, /\w+$/] : this.prefixMatch(options);
      const token = context.matchBefore(match);
      return token || context.explicit ? { from: token ? token.from : context.pos, options, validFor } : null;
    },

    mapPos(line: number, col: number, doc, offset) {
      return doc.line(line + offset.line).from + col + (line == 1 ? offset.col - 1 : -1);
    },

    translateDiagnostic(input, doc, offset) {
      let start = this.mapPos(input.line, input.column, doc, offset);
      let result = {
        from: start,
        to: input.endLine != null && input.endColumn != 1 ? this.mapPos(input.endLine, input.endColumn, doc, offset) : start,
        message: input.message,
        source: input.ruleId ? "eslint:" + input.ruleId : "eslint",
        severity: input.severity == 1 ? "warning" : "error",
      };
      return result;
    },
  },

  mounted() {

    const javascriptLang = javascript();

    // THEME
    const settings = {
      background: '#FFFFFF',
      foreground: '#000000',
      caret: '#000000',
      selection: '#FFFD0054',
      gutterBackground: '#FFFFFF',
      gutterForeground: '#00000070',
      lineHighlight: '#00000008',
    }

    const actionEditorTheme = EditorView.theme({
      '&': {
        backgroundColor: settings.background,
        color: settings.foreground,
      },
      ".cm-scroller": { overflow: "auto" },
      '.cm-content': {
        caretColor: settings.caret,
      },
      '.cm-cursor, .cm-dropCursor': {
        borderLeftColor: settings.caret,
      },
      '&.cm-focused .cm-selectionBackgroundm .cm-selectionBackground, .cm-content ::selection':
      {
        backgroundColor: settings.selection,
      },
      '.cm-activeLine': {
        backgroundColor: settings.lineHighlight,
      },
      '.cm-gutters': {
        backgroundColor: settings.gutterBackground,
        color: settings.gutterForeground,
      },
      '.cm-activeLineGutter': {
        backgroundColor: settings.lineHighlight,
      },
    });

    // Highlight
    const myHighlightStyle = HighlightStyle.define([
      {
        tag: t.comment,
        color: '#CFCFCF',
      },
      {
        tag: [t.number, t.bool, t.null],
        color: '#E66C29',
      },
      {
        tag: [
          t.className,
          t.definition(t.propertyName),
          t.function(t.variableName),
          t.labelName,
          t.definition(t.typeName),
        ],
        color: '#2EB43B',
      },
      {
        tag: t.keyword,
        color: '#D8B229',
      },
      {
        tag: t.operator,
        color: '#4EA44E',
      },
      {
        tag: [t.definitionKeyword, t.modifier],
        color: '#925A47',
      },
      {
        tag: t.string,
        color: '#704D3D',
      },
      {
        tag: t.typeName,
        color: '#2F8996',
      },
      {
        tag: [t.variableName, t.propertyName],
        color: '#77ACB0',
      },
      {
        tag: t.self,
        color: '#77ACB0',
      },
      {
        tag: t.regexp,
        color: '#E3965E',
      },
      {
        tag: [t.tagName, t.angleBracket],
        color: '#BAA827',
      },
      {
        tag: t.attributeName,
        color: '#B06520',
      },
      {
        tag: t.derefOperator,
        color: '#000',
      },
    ]);
    
    const inputListener = EditorView.updateListener.of((v: ViewUpdate) => {
      if(v.docChanged) {
        if(this.cleanLingeringErrors) {
          
          const jsLintSpec = setDiagnostics(this.view.state, []);
      
          this.view.dispatch(jsLintSpec);
          
          this.cleanLingeringErrors = false;
        }
      }
    })

    this.startState = EditorState.create({
      doc: "",
      extensions: [basicSetup, javascriptLang, keymap.of(defaultKeymap), actionEditorTheme, syntaxHighlighting(myHighlightStyle), lintGutter(), inputListener, autocompletion({
        override: [
          (context: CompletionContext) => {
            return this.completionFromList(context, this.scriptedArFunctions, 'function');
          }
        ]
      })]
    });

    this.view = new EditorView({
      state: this.startState,
      parent: this.$refs.functionEditor as Element,
    })

    if (this.template) {
      this.view.dispatch({
          changes: { from: 0, to: this.view.state.doc.length, insert: this.template }
      });
    }
  }
},);
