export const sarVarMap = {
  campaign: {
    functions: [
      'PauseCampaign(id)',
      'ResumeCampaign(id)',
      'SetCampaignBudget(id, budget)',
      'DisableRule()',
      'StopRule()',
      'NextResult()',
      'StopAction()',
      'Log(message)',
      'GetHistoricValue(valueName, lookbackDayStart, lookbackDayEnd)',
      'ExistsInStorefront(id, countryCode)',
      'SetCampaignDailyCap(id, dailyCap)',
    ],
    vars: [
      'name',
      'id',
      'state',
      'dailyBudget',
      'budget',
      'cpm',
      'spend',
      'cpa',
      'cpt',
      'impressions',
      'taps',
      'installs',
      'ttr',
      'cr',
      'dateCreated',
      'index',
      'resultCount',
      'Date',
      'goalEventCount',
      'goalRevenue',
      'goalRevenuePer',
      'goalCostPer',
      'goalROAS',
      'goalARPU',
      'goalAMPU',
      'campaignBudgetRemainingToday',
    ],
    misc: ['keyName', 'value', 'amountToSum', 'message'],
    propList: {},
  },
  campaign_blockly: {
    functions: [
      'PauseCampaign(id)',
      'ResumeCampaign(id)',
      'SetCampaignBudget(id, budget)',
      'DisableRule()',
      'StopRule()',
      'NextResult()',
      'StopAction()',
      'Log(message)',
      'GetHistoricValue(valueName, lookbackDayStart, lookbackDayEnd)',
      'ExistsInStorefront(id, countryCode)',
      'SetCampaignDailyCap(id, dailyCap)',
    ],
    vars: [
      'name',
      'id',
      'state',
      'dailyBudget',
      'budget',
      'cpm',
      'spend',
      'cpa',
      'cpt',
      'impressions',
      'taps',
      'installs',
      'ttr',
      'cr',
      'dateCreated',
      'index',
      'resultCount',
      'Date',
      'goalEventCount',
      'goalRevenue',
      'goalRevenuePer',
      'goalCostPer',
      'goalROAS',
      'goalARPU',
      'goalAMPU',
      'campaignBudgetRemainingToday',
    ],
  },
  adgroup: {
    functions: [
      'PauseAdgroup(id)',
      'ResumeAdgroup(id)',
      'DisableRule()',
      'StopRule()',
      'NextResult()',
      'StopAction()',
      'Log(message)',
      'GetHistoricValue(valueName, lookbackDayStart, lookbackDayEnd)',
      'SetDefaultMaxCPTBid(id,value)',
      'ExistsInStorefront(id, countryCode)',
    ],
    vars: [
      'name',
      'id',
      'state',
      'spend',
      'cpa',
      'cpt',
      'impressions',
      'taps',
      'installs',
      'ttr',
      'cr',
      'defaultMaxCPTBid',
      'dateCreated',
      'index',
      'resultCount',
      'Date',
      'campaignId',
      'goalEventCount',
      'goalRevenue',
      'goalRevenuePer',
      'goalCostPer',
      'goalROAS',
      'goalARPU',
      'goalAMPU',
      'campaignBudgetRemainingToday',
    ],
    misc: ['keyName', 'value', 'amountToSum', 'message'],
    propList: {},
  },
  adgroup_blockly: {
    functions: [
      'PauseAdgroup(id)',
      'ResumeAdgroup(id)',
      'DisableRule()',
      'StopRule()',
      'NextResult()',
      'StopAction()',
      'Log(message)',
      'GetHistoricValue(valueName, lookbackDayStart, lookbackDayEnd)',
      'SetDefaultMaxCPTBid(id,value)',
      'ExistsInStorefront(id, countryCode)',
    ],
    vars: [
      'name',
      'id',
      'state',
      'spend',
      'cpa',
      'cpt',
      'impressions',
      'taps',
      'installs',
      'ttr',
      'cr',
      'defaultMaxCPTBid',
      'dateCreated',
      'index',
      'resultCount',
      'Date',
      'campaignId',
      'goalEventCount',
      'goalRevenue',
      'goalRevenuePer',
      'goalCostPer',
      'goalROAS',
      'goalARPU',
      'goalAMPU',
      'campaignBudgetRemainingToday',
    ],
  },
  keyword: {
    functions: [
      'CopyKeyword(id, toAdgroupId, deleteOriginal)',
      'PauseKeyword(id)',
      'ResumeKeyword(id)',
      'SetKeywordBidAmount(id, bidAmount)',
      'DisableRule()',
      'StopRule()',
      'NextResult()',
      'StopAction()',
      'Log(message)',
      'GetHistoricValue(valueName, lookbackDayStart, lookbackDayEnd)',
      'ExistsInStorefront(id, countryCode)',
    ],
    vars: [
      'name',
      'id',
      'state',
      'bidAmount',
      'spend',
      'cpa',
      'cpt',
      'impressions',
      'taps',
      'installs',
      'ttr',
      'cr',
      'dateCreated',
      'index',
      'resultCount',
      'Date',
      'adGroupId',
      'campaignId',
      'goalEventCount',
      'goalRevenue',
      'goalRevenuePer',
      'goalCostPer',
      'goalROAS',
      'goalARPU',
      'goalAMPU',
      'matchType',
      'campaignBudgetRemainingToday',
      'SOVTerm',
      'SOVImpressionShareHigh',
      'SOVImpressionShareLow',
      'SOVRank',
      'SOVSearchPopularity',
      'SOVRegion',
      'SOVMatched',
      'SOVMatchedDaysAgo',
    ],
    misc: ['keyName', 'value', 'amountToSum', 'message', 'toAdgroupId', 'deleteOriginal'],
    propList: {},
  },
  keyword_blockly: {
    functions: [
      'CopyKeyword(id, toAdgroupId, deleteOriginal)',
      'PauseKeyword(id)',
      'ResumeKeyword(id)',
      'SetKeywordBidAmount(id, bidAmount)',
      'DisableRule()',
      'StopRule()',
      'NextResult()',
      'StopAction()',
      'Log(message)',
      'GetHistoricValue(valueName, lookbackDayStart, lookbackDayEnd)',
      'ExistsInStorefront(id, countryCode)',
    ],
    vars: [
      'name',
      'id',
      'state',
      'bidAmount',
      'spend',
      'cpa',
      'cpt',
      'impressions',
      'taps',
      'installs',
      'ttr',
      'cr',
      'dateCreated',
      'index',
      'resultCount',
      'Date',
      'adGroupId',
      'campaignId',
      'goalEventCount',
      'goalRevenue',
      'goalRevenuePer',
      'goalCostPer',
      'goalROAS',
      'goalARPU',
      'goalAMPU',
      'campaignBudgetRemainingToday',
    ],
    SovVars: [
      'SOVTerm',
      'SOVImpressionShareHigh',
      'SOVImpressionShareLow',
      'SOVRank',
      'SOVSearchPopularity',
      'SOVRegion',
      'SOVMatched',
      'SOVMatchedDaysAgo',
    ],
  },
  onetime: {
    functions: [
      'PauseCampaign()',
      'ResumeCampaign()',
      'SetCampaignBudget()',
      'PauseAdgroup()',
      'ResumeAdgroup()',
      'PauseKeyword()',
      'ResumeKeyword()',
      'SetKeywordBidAmount()',
      'DisableRule()',
      'StopRule()',
      'StopAction()',
      'Log()',
      'WriteToStorage("keyName", keyValue)',
      'ReadFromStorage("keyName", valueIfNotPresent)',
      'SendEmail("email", "subject", "body")',
      'SendEmailMultiple(["email1","email2"], "subject", "body")',
      'CreateReport("Report Name", ["header1","header2"])',
    ],
    vars: [],
    misc: [],
    propList: {},
  },
  onetime_blockly: {
    functions: [
      'PauseCampaign()',
      'ResumeCampaign()',
      'SetCampaignBudget()',
      'PauseAdgroup()',
      'ResumeAdgroup()',
      'PauseKeyword()',
      'ResumeKeyword()',
      'SetKeywordBidAmount()',
      'DisableRule()',
      'StopRule()',
      'StopAction()',
      'Log()',
      'WriteToStorage("keyName", keyValue)',
      'ReadFromStorage("keyName", valueIfNotPresent)',
      'SendEmail("email", "subject", "body")',
      'SendEmailMultiple(["email1","email2"], "subject", "body")',
      'CreateReport("Report Name", ["header1","header2"])',
    ],
    vars: [],
  },
};
