


















































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import DatePicker from 'src/components/shared/DatePicker/DatePicker.vue';
import { I_TOTAL_SPEND, TOTAL_SPEND } from 'src/graphql/queries/billing.queries';
import { ALL_USERS } from 'src/utils/apollo-queries';
import BillingFormService from './services/billing-form.service';
import { ApolloQueryResult } from '@apollo/client';
import BillingOutput from './BillingOutput/BillingOutput.vue';
import { UserService } from 'src/services/feature-services/user.service';

export default Vue.extend({
  name: 'BillingQuery',
  components: { DatePicker, BillingOutput },
  data() {
    return {
      billingFormService: null,
      customerList: [],
      billingResponse: null,
      fullscreenLoader: null,
    };
  },
  created() {
    this.billingFormService = new BillingFormService();
  },
  apollo: {
    getCustomerList: {
      query: ALL_USERS,
      update(data) {
        this.updateFilteredUsers(data.allUsers);
      },
    },
  },
  computed: {
    isDisabled() {
      if (this.billingFormService.isCompleteFormFilled) return false;
      return true;
    },
  },
  methods: {
    updateFilteredUsers(userList) {
      const uniqueCompanyUsers = UserService.keepOneUserPerCompany(userList);
      this.customerList = this._filterUsersWithBlankCompanyName(uniqueCompanyUsers);
      UserService.sortUsersBasedOnAlphabeticalCompanyName(this.customerList);
    },
    _filterUsersWithBlankCompanyName(users) {
      return users.filter((user) => !!user.CompanyName);
    },
    async calculateBill() {
      if (this.billingFormService.isFormInvalid) {
        this.billingResponse = {};
        return this.handleFormInvalidity();
      }
      await this.getTotalBilling();
    },
    handleFormInvalidity() {
      if (this.billingFormService.isDateSelectInvalid) {
        return this.$notify.error('Start Date can not be greater than End Date');
      }
      if (this.billingFormService.isEnteredAmountNegative) {
        return this.$notify.error('Eneterd Amount can not be negative');
      }
    },
    async getTotalBilling() {
      try {
        this.fullscreenLoader = this.$loading.show();
        const response: ApolloQueryResult<I_TOTAL_SPEND> = await this.$apollo.query({
          query: TOTAL_SPEND,
          variables: this._getQueryVariables(),
        });
        this._handleResponse(response);
      } catch (error) {
        console.log(error);
      }
      this.fullscreenLoader.hide();
    },
    _handleResponse(response) {
      const billData = response.data.getCBSpend;
      if (!billData) {
        this.billingResponse = {};
        this.$notify.error('Subscription Details not available for this selection');
        return;
      }
      this.billingResponse = billData;
    },
    _getQueryVariables() {
      const formData = this.billingFormService.formData;
      return {
        teamId: formData.customer,
        from: formData.startDate,
        to: formData.endDate,
        monthlyMinAmount: formData.minAmount.toString(),
        asaSpendPercent: formData.asaSpend.toString(),
      };
    },
  },
});
