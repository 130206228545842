





































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import JsonViewer from 'vue-json-viewer';
import { sarVarMap } from 'src/utils/objects';
import { v4 as uuidv4 } from 'uuid';
import {
  GET_ACTIVITY_LOGS,
  GET_CUSTOM_FN_FILETREE,
  GET_CUSTOM_FN,
  DELETE_CUSTOM_FN,
  PB_PVT_FN,
  LOCK_UNLOCK_FN,
} from 'src/utils/apollo-queries';
import * as _ from 'lodash';
import * as moment from 'moment';
import FunctionEditor from 'src/components/super-admin/FunctionEditor.vue';
import {
  CHANGE_ACTIVITY_WATCHED,
  DELETE_ACTIVITY_LOG,
  GET_ACTIVITY_LOGS,
  GET_LOG_RETENTION_PERIOD,
  LOG_RETENTION_PERIOD,
} from 'src/utils/apollo-queries';
import DecisionAlert from 'src/components/DecisionAlert.vue';
import DeleteIntegration from 'src/components/integration/DeleteIntegration.vue';
import BillingQuery from './BillingQuery/BillingQuery.vue';
import Vue from 'vue';
import { MOCK_DATA_HYDRATION, UPSERT_FILETREE, UPSERT_FUNCTION } from 'src/utils/apollo-mutations';
import { ALL_USERS, GET_MOCK_DATA } from 'src/utils/apollo-queries';
export default Vue.extend({
  name: 'ActivityLog',
  components: { DecisionAlert, DeleteIntegration, FunctionEditor, JsonViewer, BillingQuery },
  data() {
    const today = moment().utc().format('YYYY-MM-DD');
    return {
      syncChangesFn: () => {
        return;
      },
      copying: false,
      activeTreeItems: [],
      moveItemDialog: false,
      validateAndSave: false,
      validateAndTestRun: false,
      functionDetails: {},
      currentCode: '',
      currentTemplate: '',
      validate: null,
      sarFunctions: {},
      folderNameModel: '',
      currentTreeItem: null,
      currentFunction: {},
      treeItemDialog: false,
      renameFolderDialog: false,
      newFolderName: '',
      files: {
        html: 'mdi-language-html5',
        js: 'mdi-language-javascript',
        json: 'mdi-json',
        md: 'mdi-markdown',
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
        xls: 'mdi-file-excel',
      },
      tree: [],
      sarFileTree: [],
      menuTree: [],
      tab: null,
      loading: false,
      items: ['Activity Log', 'Mock Data', 'SAR Functions', 'Billing Query'],
      search: '',
      companyName: '',
      message: '',
      activityLogs: [],
      filteredActivityLogs: [],
      uniqueFeatureNames: [],
      uniqueLogTypes: [],
      selectedFeature: [],
      selectedLogType: [],
      memberDetails: [],
      fullscreenLoader: null,
      selectedTeamID: null,
      integrationData: null,
      maxDate: new Date().toISOString().split('T')[0],
      filterDateRangeModel: [moment().utc().subtract(1, 'days').format('YYYY-MM-DD'), today],
      filterDateRange: [moment().utc().subtract(1, 'days').format('YYYY-MM-DD'), today],
      rangePicker: false,
      reportDateRange: [moment().utc().subtract(1, 'days').format('YYYY-MM-DD'), today],
      logIdForDeletion: '',
      decisionAlertFlag: false,
      decisionAlertMessage: '',
      decisionAlertIcon: 2,
      decisionAlertData: {},
      decisionAlertTitle: '',
      retentionStartDate: [],
      retentionStatus: false,
      saving: false,
      retentionPeriodDialog: false,
      oldRetentionPeriod: 0,
      retentionPeriod: 0,
      volume: {
        TeamID: '',
        appCount: null,
        campaignCount: null,
        adgroupCount: null,
        keywordCount: null,
      },
      mockDataItems: [],
      mockDataHeader: [
        { text: 'Account', value: 'Email' },
        { text: 'Name', value: 'name' },
        { text: 'Org', value: 'org' },
        { text: 'App Count', value: 'appCount' },
        { text: 'Campaign Count', value: 'campaignCount' },
        { text: 'Adgroup Count', value: 'adgroupCount' },
        { text: 'Keyword Count', value: 'keywordCount' },
        { text: 'Status', value: 'status' },
        { text: 'Delete', value: 'delete' },
      ],
      numberRules: [
        (v) => !!v || 'This field is required', //this.$t('createCampaignRules.budgetRequired')
        (v) => !!/^[0-9]*$/gi.test(v) || 'Only numbers are allowed', // this.$t('createCampaignRules.validBudget')
      ],
      testRunDialog: false,
      testRunParams: '',
      testRunLogs: '',
    };
  },
  watch: {
    sarFileTree: {
      handler() {
        this.callSyncChanges();
      },
      deep: true,
    },
    message(data) {
      this.getFilteredLogs();
    },
    selectedFeature(data) {
      this.getFilteredLogs();
    },
    selectedLogType(data) {
      this.getFilteredLogs();
    },
  },
  apollo: {
    getActivityLogs: {
      query: GET_ACTIVITY_LOGS,
      variables() {
        return {
          reportDateRange: this.reportDateRange,
        };
      },
      update(data) {
        if (data.getActivityLogs && data.getActivityLogs.length > 0) {
          const sarLogs = data.getActivityLogs.filter((x) => x.featureName === 'Automation v2');
          const sarStartLogs = sarLogs.filter((x) => x.message === 'Scheduled run start');
          const sarTaskIds = sarLogs.map((x) => x.data.taskId);
          data.getActivityLogs.map((x) => {
            if (x.featureName !== 'Automation v2') return;
            if (x.data && x.data.taskId && sarTaskIds.includes(x.data.taskId)) {
              const log = sarStartLogs.find((y) => y.data.taskId === x.data.taskId);
              if (!log) return;
              x.data.apple_api_calls = log.data.apple_api_calls;
            }
          });
          this.activityLogs = data.getActivityLogs;
          this.filteredActivityLogs = data.getActivityLogs;
          this.getFiltersList(data.getActivityLogs);
        }
      },
    },

    getLogRetentionPeriod: {
      query: GET_LOG_RETENTION_PERIOD,
      update(res) {
        this.oldRetentionPeriod = Number(res?.getLogRetentionPeriod?.data);
        this.retentionPeriod = Number(res?.getLogRetentionPeriod?.data);
      },
    },
  },
  computed: {
    countMockdata() {
      return (
        this.selectedTeamID &&
        this.volume.appCount !== null &&
        this.volume.appCount !== '' &&
        this.volume.campaignCount !== null &&
        this.volume.campaignCount !== '' &&
        this.volume.adgroupCount !== null &&
        this.volume.adgroupCount !== '' &&
        this.volume.keywordCount !== null &&
        this.volume.keywordCount !== ''
      );
    },
    filterDateRangeText() {
      if (this.filterDateRange[0] > this.filterDateRange[1]) {
        const maxDate = this.filterDateRange[0];
        this.filterDateRange[0] = this.filterDateRange[1];
        this.filterDateRange[1] = maxDate;
      }
      if (this.retentionPeriod > 0 && this.retentionStatus === false) {
        this.retentionStatus = true;
        this.filterDateRange = [
          moment().utc().subtract(this.retentionPeriod, 'days').format('YYYY-MM-DD'),
          moment().utc().format('YYYY-MM-DD'),
        ];

        this.retentionStartDate = [
          moment().utc().subtract(this.retentionPeriod, 'days').format('YYYY-MM-DD'),
          moment().utc().format('YYYY-MM-DD'),
        ];

        this.filterDateRangeModel = this.retentionStartDate;
      }
      return this.filterDateRange.join(' - ');
    },
    activityLogHeaders: {
      cache: false,
      get() {
        return [
          {
            text: 'Date Created(UTC)',
            value: 'createdOn',
          },
          {
            text: 'Account name',
            value: 'companyName',
            filter: (f) => {
              return (f + '').toLowerCase().includes(this['companyName'].toLowerCase());
            },
          },
          {
            text: 'Log type',
            value: 'logType',
          },
          {
            text: 'Feature name',
            value: 'featureName',
          },
          {
            text: 'Log Message',
            value: 'message',
            // filter: (f) => {
            //   return (f + '')
            //     .toLowerCase()
            //     .includes(this['message'].toLowerCase());
            // },
          },
          {
            text: 'Options',
            value: 'options',
          },
          {
            text: 'Details',
            value: 'data-table-expand',
          },
        ];
      },
    },
  },
  methods: {
    closeTestRunDialog() {
      this.testRunParams = '';
      this.testRunLogs = '';
      this.testRunDialog = false;
    },
    testFunction() {
      this.testRunDialog = true;
    },
    openMoveDialog(item, copy) {
      this.copying = !!copy;
      this.currentTreeItem = item;
      this.menuTree = _.cloneDeep(this.sarFileTree);
      this.menuTree[0] = this.createFolderTree(this.menuTree[0]);
      this.moveItemDialog = true;
    },
    moveTreeItem() {
      const targetId = this.activeTreeItems[0];
      this.moveItemRecur(this.sarFileTree[0], targetId);
      this.closeMoveDialog();
    },
    copyTreeItem() {
      const targetId = this.activeTreeItems[0];
      this.copyItemRecur(this.sarFileTree[0], targetId);
      this.closeMoveDialog();
    },
    moveItemRecur(item, targetId) {
      if (!item || !Array.isArray(item.children)) return;

      item.children = item.children.filter((c) => c.id !== this.currentTreeItem.id);

      if (item.id === targetId) item.children.push(this.currentTreeItem);

      for (const child of item.children) this.moveItemRecur(child, targetId);
    },
    async copyItemRecur(item, targetId) {
      if (!item || !Array.isArray(item.children)) return;

      if (item.id === targetId) {
        const newId = uuidv4();
        this.currentCode = this.currentTemplate;
        this.currentFunction = { ...this.currentFunction, id: newId };
        const newItem = { ...this.currentTreeItem, id: newId };

        await this.saveFunction();

        item.children.push(newItem);

        this.currentFunction = {};
        this.functionDetails = {};
      }

      for (const child of item.children) this.copyItemRecur(child, targetId);
    },
    createFolderTree(item) {
      if (!item) return;

      item.children = item.children.filter((c) => !c.file);

      for (let child of item.children) {
        child = this.createFolderTree(child);
      }

      return item;
    },
    closeMoveDialog() {
      this.moveItemDialog = false;
      this.activeTreeItems = [];
    },
    validateAndSaveFn() {
      this.validateAndSave = true;
      this.validateFunction();
    },
    validateAndTestFn() {
      this.validateAndTestRun = true;
      this.validateFunction();
    },
    removeTreeItem(item, id) {
      if (!item || !Array.isArray(item?.children)) return;

      const lengthBefore = item.children.length;
      item.children = item.children.filter((n) => n.id !== id);
      if (item.children.length !== lengthBefore) return;

      for (const child of item.children) {
        this.removeTreeItem(child, id);
      }
    },
    lockUnlockFn(id) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: LOCK_UNLOCK_FN,
          variables: {
            id,
            currentValue: this.functionDetails.isLocked,
          },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          if (res.data.lockUnlockCustomFn.successMessage) {
            this.functionDetails = {
              ...this.functionDetails,
              isLocked: !this.functionDetails.isLocked,
            };
          }
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          this.fullscreenLoader.hide();
        });
    },
    publicPrivateFn(id) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: PB_PVT_FN,
          variables: {
            id,
            currentValue: this.functionDetails.isPublic,
          },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          if (res.data.publicOrPvtCustomFn.successMessage) {
            this.functionDetails = {
              ...this.functionDetails,
              isPublic: !this.functionDetails.isPublic,
            };
          }
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          this.fullscreenLoader.hide();
        });
    },
    deleteFolder(id) {
      this.removeTreeItem(this.sarFileTree[0], id);

      this.currentFunction = {};
      this.functionDetails = {};
      this.currentTemplate = '';
    },
    deleteFunction(id) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_CUSTOM_FN,
          variables: {
            id,
          },
          fetchPolicy: 'network-and-cache',
        })
        .then((res) => {
          if (res.data.deleteCustomFn.successMessage) {
            this.removeTreeItem(this.sarFileTree[0], id);
            this.currentFunction = {};
            this.functionDetails = {};
            this.currentTemplate = '';
          } else {
          }
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          this.fullscreenLoader.hide();
        });
    },
    openDeleteFunction(item) {
      this.decisionAlertTitle = 'Delete Function';
      this.decisionAlertMessage = 'Are you sure you want to delete this function?';
      this.decisionAlertData = { from: 'openDeleteFunction', id: item.id };
      this.decisionAlertFlag = true;
    },
    openDeleteFolder(item) {
      this.decisionAlertTitle = 'Delete Folder';
      this.decisionAlertMessage = 'Are you sure you want to delete this folder? All of its contents will be lost.';
      this.decisionAlertData = { from: 'openDeleteFolder', id: item.id };
      this.decisionAlertFlag = true;
    },
    async saveFunction() {
      this.fullscreenLoader = this.$loading.show();
      this.validateAndSave = false;
      const result = await this.$apollo.mutate({
        mutation: UPSERT_FUNCTION,
        variables: {
          fn: {
            id: this.currentFunction.id,
            name: this.currentFunction.name,
            description: this.functionDetails.description,
            code: this.currentCode,
          },
        },
      });

      if (result.data.upsertCustomSarFn.successMessage) {
        this.fullscreenLoader.hide();
      } else {
        this.$notify.error('An error occurred while saving file tree');
        throw this.fullscreenLoader.hide();
      }
    },
    getCustomFunction(item) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_CUSTOM_FN,
          variables: {
            id: item.id,
          },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          this.currentFunction = item;
          if (res.data.getSarCustomFunction) {
            this.functionDetails = {};
            this.functionDetails.isFromDB = true;
            this.functionDetails.id = item.id;
            this.currentFunction.name = res.data.getSarCustomFunction?.name;
            this.functionDetails.description = res.data.getSarCustomFunction?.description;
            this.functionDetails.isLocked = res.data.getSarCustomFunction?.isLocked;
            this.functionDetails.isPublic = res.data.getSarCustomFunction?.isPublic;
          }
          this.currentTemplate = '';
          this.currentTemplate = res.data.getSarCustomFunction?.code;
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          this.fullscreenLoader.hide();
        });
    },
    getFileTree() {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_CUSTOM_FN_FILETREE,
          fetchPolicy: 'network-only',
          variables: {},
        })
        .then((res) => {
          this.sarFileTree = [res.data.getCustomFnsFileTree];
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          console.log(error);
          this.fullscreenLoader.hide();
        });
    },
    callSyncChanges() {
      if (typeof this.syncChangesFn.cancel == 'function') {
        this.syncChangesFn.cancel();
        this.syncChangesFn = () => {
          return;
        };
      }

      this.syncChangesFn = _.debounce(this.syncChnages, 3000);

      this.syncChangesFn();
    },

    async syncChnages() {
      const result = await this.$apollo.mutate({
        mutation: UPSERT_FILETREE,
        variables: {
          tree: this.sarFileTree[0],
        },
      });

      if (result.data.upsertFileTree.successMessage) {
      } else {
        this.$notify.error('An error occurred while saving file tree');
      }
    },
    functionValues(values) {
      if (values.name && values.isValid) {
        this.currentFunction.name = values.name;
        this.currentCode = values.code;

        if (values.isValid) {
          if (this.validateAndSave) this.saveFunction();
          if (this.validateAndTestRun) this.testFunction();
        }

        this.validateAndSave = false;
        this.validateAndTestRun = false;
      }
    },
    validateFunction() {
      this.validate = { now: Date.now() };
    },
    selectFunction(item) {
      this.getCustomFunction(item);
    },
    startFunctionCreation(item) {
      this.currentTreeItem = item;
      this.currentTreeItem.open = true;
      this.currentTemplate = 'function fn() {\n // write your logic here\n}';
      this.currentFunction = { id: uuidv4(), name: 'fn', file: 'js' };
      this.currentTreeItem.children.push(this.currentFunction);
    },
    openCreateFolder(item) {
      this.currentTreeItem = item;
      this.treeItemDialog = true;
    },
    createFolder() {
      this.currentTreeItem.children.push({
        name: this.folderNameModel,
        id: uuidv4(),
        children: [],
      });
      this.closeFolderDialog();
    },
    closeFolderDialog() {
      this.currentTreeItem = null;
      this.treeItemDialog = false;
      this.folderNameModel = '';
    },
    openRenameDialog(item) {
      this.currentTreeItem = item;
      this.newFolderName = item.name;
      this.renameFolderDialog = true;
    },
    closeRenameDialog() {
      this.renameFolderDialog = false;
      this.currentTreeItem = null;
      this.newFolderName = '';
    },
    renameFolder() {
      if (this.currentTreeItem && this.newFolderName.trim() !== '') {
        const updateName = (items) => {
          items.forEach((item) => {
            if (item.id === this.currentTreeItem.id) {
              item.name = this.newFolderName.trim();
            } else if (item.children) {
              updateName(item.children);
            }
          });
        };
        updateName(this.sarFileTree);

        this.closeRenameDialog();
      } else {
        this.$notify.error('Folder name cannot be empty');
      }
    },
    openDeleteIntegrationDialog(TeamID) {
      // TODO replacable with actual id if dynamic
      this.integrationData = {
        id: 'MOCK_1ad85d1b-34af-49dc-8b9f-62fa778c2b72',
        TeamID: TeamID,
        type: 'APPLE_SEARCH_ADS',
        now: Date.now(),
      };
    },
    async addMockData() {
      try {
        if (this.selectedTeamID) {
          this.volume.TeamID = this.selectedTeamID;
          this.volume.appCount = this.volume.appCount == null ? this.volume.appCount : parseInt(this.volume.appCount);
          this.volume.campaignCount = this.volume.campaignCount == null ? this.volume.campaignCount : parseInt(this.volume.campaignCount);
          this.volume.adgroupCount = this.volume.adgroupCount == null ? this.volume.adgroupCount : parseInt(this.volume.adgroupCount);
          this.volume.keywordCount = this.volume.keywordCount == null ? this.volume.keywordCount : parseInt(this.volume.keywordCount);

          if (this.volume.appCount * this.volume.campaignCount * this.volume.adgroupCount * this.volume.keywordCount > 1000000) {
            return this.$notify.warning('Product of apps, campaigns, adgroups and keywords can not be more than 1000000');
          }

          this.fullscreenLoader = this.$loading.show();

          try {
            await this.$apollo.mutate({
              mutation: MOCK_DATA_HYDRATION,
              variables: { volumes: this.volume },
            });
            this.selectedTeamID = '';
            this.volume = {
              TeamID: '',
              appCount: null,
              campaignCount: null,
              adgroupCount: null,
              keywordCount: null,
            };

            this.allMockData();

            this.$notify.success('Mock data is getting generated. It can take several minutes to fully populate.');
          } catch (error) {}

          this.fullscreenLoader.hide();
        }
      } catch (error) {
        console.error('Error occurred:', error);
      }
    },
    async allMockData() {
      await this.$apollo
        .query({
          query: GET_MOCK_DATA,
          fetchPolicy: 'network-only',
          variables: {},
        })
        .then((res) => {
          this.mockDataItems = res.data.getMockData;

          this.mockDataItems.map((md) => {
            const member = this.memberDetails.find((m) => m.TeamID === md.TeamID);

            if (member) md.Email = member.Email;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async allMemberDetails() {
      await this.$apollo
        .query({
          query: ALL_USERS,
          fetchPolicy: 'network-only',
          variables: {},
        })
        .then((res) => {
          this.memberDetails = res.data.allUsers;
          this.allMockData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async applyDateRange() {
      this.fullscreenLoader = this.$loading.show();
      this.filterDateRange = this.filterDateRangeModel;
      try {
        this.rangePicker = false;
        const result2 = await this.$apollo
          .query({
            query: GET_ACTIVITY_LOGS,
            variables: {
              reportDateRange: this.filterDateRange,
            },
          })
          .then((res) => {
            if (res.data.getActivityLogs && res.data.getActivityLogs.length > 0) {
              const sarLogs = res.data.getActivityLogs.filter((x) => x.featureName === 'Automation v2');
              const sarStartLogs = sarLogs.filter((x) => x.message === 'Scheduled run start');
              const sarTaskIds = sarLogs.map((x) => x.data.taskId);
              res.data.getActivityLogs.map((x) => {
                if (x.featureName !== 'Automation v2') return;
                if (x.data && x.data.taskId && sarTaskIds.includes(x.data.taskId)) {
                  const log = sarStartLogs.find((y) => y.data.taskId === x.data.taskId);
                  if (!log) return;
                  x.data.apple_api_calls = log.data.apple_api_calls;
                }
              });
              this.activityLogs = res.data.getActivityLogs;
              this.filteredActivityLogs = res.data.getActivityLogs;
              this.getFiltersList(res.data.getActivityLogs);
              this.fullscreenLoader.hide();
            }
          });
      } catch (error) {
        console.log(error);
        this.$notify.error('Something went wrong while filtering');
      }
    },
    getFiltersList(activityLogs) {
      this.uniqueFeatureNames = [...new Set(activityLogs.map((item) => item.featureName))];
      this.uniqueLogTypes = [...new Set(activityLogs.map((item) => item.logType.toLowerCase()))];
    },

    getFilteredLogs() {
      this.filteredActivityLogs = this.activityLogs;
      let filteredMessageLogs = [];
      if (this.message != '') {
        this.filteredActivityLogs.filter((log) => {
          if (log.featurePath !== null && log.featurePath.toLowerCase().includes(this.message.toLowerCase())) {
            filteredMessageLogs.push(log);
          }
          if (log.message !== null && log.message.toLowerCase().includes(this.message.toLowerCase())) {
            filteredMessageLogs.push(log);
          }
        });
        this.filteredActivityLogs = [...new Set([...filteredMessageLogs])];
      }
      if (this.selectedFeature.length > 0) {
        this.filteredActivityLogs = this.filteredActivityLogs.filter((activityLog) =>
          this.selectedFeature.includes(activityLog.featureName)
        );
      }

      if (this.selectedLogType.length > 0) {
        this.filteredActivityLogs = this.filteredActivityLogs.filter((activityLog) =>
          this.selectedLogType.includes(activityLog.logType.toLowerCase())
        );
      }
    },

    openDeleteDialog(logId) {
      this.logIdForDeletion = logId;
      this.decisionAlertTitle = 'Delete Log';
      this.decisionAlertMessage = 'Are you sure you want to delete this log?';
      this.decisionAlertFlag = true;
    },

    decisionOkAction(event) {
      if (event.from === 'openDeleteFolder') {
        this.deleteFolder(event.id);
      } else if (event.from === 'openDeleteFunction') {
        this.deleteFunction(event.id);
      } else {
        this.deleteLog();
      }

      this.decisionAlertFlag = false;
    },

    async deleteLog() {
      await this.$apollo
        .query({
          query: DELETE_ACTIVITY_LOG,
          variables: {
            logId: this.logIdForDeletion,
          },
        })
        .then((res) => {
          this.$apollo.queries.getActivityLogs.refetch();
          this.$notify.success('Log Deleted');
        })
        .catch((error) => {
          this.$notify.error('Error while deleting rule');
        });
    },

    async changeWatched(logId, watched) {
      await this.$apollo
        .query({
          query: CHANGE_ACTIVITY_WATCHED,
          variables: {
            logId: logId,
            watched: watched,
          },
        })
        .then((res) => {
          this.$notify.success('Log watched status changed');
          this.$apollo.queries.getActivityLogs.refetch();
        })
        .catch((error) => {
          this.$notify.error('Something went wrong');
        });
    },

    copyJSON(json) {
      navigator.clipboard.writeText(JSON.stringify(json));
      this.$notify.success('Log Copied');
    },

    openRetentionPeriodDialog() {
      this.retentionPeriodDialog = true;
    },

    async changeRetentionPeriod() {
      this.fullscreenLoader = this.$loading.show();
      this.saving = true;
      await this.$apollo
        .query({
          query: LOG_RETENTION_PERIOD,
          variables: {
            activityRetentionDays: Number(this.retentionPeriod),
          },
        })
        .then((res) => {
          this.$notify.success('Retention period changed successfully');
          this.saving = false;
          this.retentionPeriodDialog = false;
        })
        .catch((error) => {
          this.$notify.error('Error while changing the retention period');
          this.retentionPeriod = this.oldRetentionPeriod;
          this.saving = false;
        });
      this.filterDateRange = [
        moment().utc().subtract(this.retentionPeriod, 'days').format('YYYY-MM-DD'),
        moment().utc().format('YYYY-MM-DD'),
      ];
      this.filterDateRangeModel = [
        moment().utc().subtract(this.retentionPeriod, 'days').format('YYYY-MM-DD'),
        moment().utc().format('YYYY-MM-DD'),
      ];
      this.retentionStartDate = [
        moment().utc().subtract(this.retentionPeriod, 'days').format('YYYY-MM-DD'),
        moment().utc().format('YYYY-MM-DD'),
      ];
      this.fullscreenLoader.hide();
    },

    unixToLocal(unix) {
      const utcDateTime = new Date(unix).toISOString();
      const utcDate = utcDateTime.substr(0, 10);
      const utcTime = utcDateTime.substr(11, 8);

      return utcDate + ' ' + utcTime;
    },

    calcTimeAgo(date) {
      const seconds = Math.floor((new Date() - date) / 1000);

      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return interval + ' yrs ago';
      }

      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + ' months ago';
      }

      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + ' d ago';
      }

      interval = Math.floor(seconds / 3600);
      if (interval > 0) {
        return interval + ' h ago';
      }

      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + ' m ago';
      }

      if (seconds < 10) return 'just now';

      return Math.floor(seconds) + ' s ago';
    },
    async executeTestRun() {
      try {
        this.testRunLogs = '';

        const vars = JSON.parse(this.testRunParams);
        const params = [];
        const values = [];

        const sarFunctionsProxied = Object.keys(this.sarFunctions).reduce((acc, key) => {
          const originalFunction = this.sarFunctions[key].split('(')[0];
          acc[originalFunction] = (...args) => {
            this.testRunLogs += `${originalFunction} ran with parameters: ${JSON.stringify(args)}\n`;
          };
          return acc;
        }, {});

        for (const key in vars) {
          params.push(key);
          values.push(vars[key]);
        }

        const functionToRun = new Function(
          ...params,
          ...Object.keys(sarFunctionsProxied),
          `
          ${this.currentCode || this.currentTemplate}
          return ${this.currentFunction.name}(...arguments);
          `
        );

        const out = functionToRun(...values, ...Object.values(sarFunctionsProxied));
        this.testRunLogs += this.currentFunction.name + ` returned: ${out}`;
      } catch (e) {
        this.testRunLogs += `Error: ${e.message}`;
      }
    },
  },
  mounted() {
    this.getFileTree();
    this.sarFunctions = sarVarMap.onetime.functions;

    if (this.retentionPeriod == 0) {
      this.$apollo.queries.getLogRetentionPeriod.refetch();
    }
  },
  created() {
    this.allMemberDetails();
  },
});
