export class UserService {
  /* @desc - 
    - It selects that user per company which has accepted invite status first,
    - if above is absent then selects the pending one. 
    - InviteStatus can only be ACCEPTED or PENDING
  */
  static keepOneUserPerCompany(users) {
    let lookup = UserService._getLookupMapperForTeamId(users);
    let result = [];
    let teamIdList = Object.keys(lookup);
    teamIdList.forEach((teamId) => {
      let userListForTeam = lookup[teamId];
      let accpetedInviteStatusUser = userListForTeam.find((user) => user.InviteStatus === 'ACCEPTED');
      let pendingInviteStatusUser = userListForTeam.find((user) => user.InviteStatus === 'PENDING');
      if (accpetedInviteStatusUser) {
        result.push(accpetedInviteStatusUser);
      } else {
        result.push(pendingInviteStatusUser);
      }
    });
    return result;
  }

  /* @desc - alphabetically sort based on compant name (ascending order) */
  static sortUsersBasedOnAlphabeticalCompanyName(users) {
    users.sort((a, b) => {
      let nameA = a.CompanyName.toLowerCase();
      let nameB = b.CompanyName.toLowerCase();

      if (nameA < nameB) return -1; // If nameA should come before nameB
      if (nameA > nameB) return 1; // If nameA should come after nameB
      return 0; // If names are equal
    });
  }

  private static _getLookupMapperForTeamId(users) {
    let lookup = {};
    users.forEach((user) => {
      if (!lookup[user.TeamID]) {
        lookup[user.TeamID] = [user];
      } else {
        lookup[user.TeamID].push(user);
      }
    });
    return lookup;
  }
}
